<template>
  <div class="flex flex-nowrap">
    <button class="mr-4 text-ne-violet-light" @click="handleSignInClick">
      {{ $t('header.login') }}
    </button>
    <AppButton
      view="accent"
      :text="$t('header.sign-up')"
      @click="handleSignUpClick"
    />
  </div>
  <div>
    <MobileFullscreenPanel
      v-model:visible="isMobileAuthPanelOpen"
      hide-bottom-navbar
      transition="opacity"
      type="cross"
      @close="isMobileAuthPanelOpen = false"
    >
      <div class="h-full tabsContainer">
        <TabGroup :selected-index="authPanelTabIndex" @change="handleTabChange">
          <TabList
            class="flex w-full justify-between border-b-[1px] border-ne-bg-4"
          >
            <Tab v-slot="{ selected }" as="template">
              <TabItem :selected="selected">
                {{ $t('header.mobile.tabs.sign-up') }}
              </TabItem>
            </Tab>
            <Tab v-slot="{ selected }" as="template">
              <TabItem :selected="selected">
                {{ $t('header.mobile.tabs.login') }}</TabItem
              >
            </Tab>
          </TabList>
          <TabPanels class="overflow-y-auto">
            <TabPanel class="grid h-full">
              <AppSignUp class="p-6 h-full" />
            </TabPanel>
            <TabPanel class="h-full">
              <AppSignIn class="p-6 h-full" />
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </div>
    </MobileFullscreenPanel>
    <MobileFullscreenPanel
      v-model:visible="isForgotPasswordVisible"
      transition="opacity"
      type="cross"
      hide-bottom-navbar
    >
      <AppForgotPassword
        class="p-6 h-full overflow-y-auto forgot-password-layout"
      />
    </MobileFullscreenPanel>
    <MobileFullscreenPanel
      v-model:visible="isNewPasswordConfirmationVisible"
      transition="opacity"
      type="cross"
      hide-bottom-navbar
    >
      <AppPasswordSentConfirmation
        class="p-6 h-full overflow-y-auto forgot-password-confirmation-layout"
      />
    </MobileFullscreenPanel>
  </div>
</template>

<script setup lang="tsx">
import MobileFullscreenPanel from '@/layouts/DefaultLayout/MobileLayout/MobileFullscreenPanel/MobileFullscreenPanel.vue'
import { h, provide, ref, Slots, toRefs, watchEffect } from 'vue'
import AppButton from '@/components/app/AppButton.vue'
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue'
import { useMobileLayoutStore } from '@/stores/mobileLayout'
import AppSignUp from '@/components/app-authentication/SignUp/SignUp.vue'
import AppSignIn from '@/components/app-authentication/SignIn/SignIn.vue'
import AppForgotPassword from '@/components/app-authentication/ForgotPassword/ForgotPassword.vue'
import { IS_SIGNIN_DIALOG_VISIBLE_KEY } from '@/components/app-authentication/DataContext/IS_SIGNIN_DIALOG_VISIBLE_KEY'
import { IS_FORGOT_PASSWORD_VISIBLE_KEY } from '@/components/app-authentication/DataContext/IS_FORGOT_PASSWORD_VISIBLE_KEY'
import AppPasswordSentConfirmation from '@/components/app-authentication/NewPasswordSentConfirmation/NewPasswordSentConfirmation.vue'
import { IS_NEW_PASSWORD_SENT_CONFIRMATION_VISIBLE_KEY } from '@/components/app-authentication/DataContext/IS_NEW_PASSWORD_SENT_CONFIRMATION_VISIBLE_KEY'

const TabItem = (
  { selected }: { selected: boolean },
  { slots }: { slots: Slots }
) =>
  h(
    'div',
    { class: 'flex-1 flex justify-center' },
    h(
      'button',
      {
        class: [
          'pb-4 text-[20px] font-semibold',
          selected
            ? 'border-b-[4px] border-ne-violet-light'
            : 'border-b-[4px] border-transparent text-ne-neutral',
        ],
      },
      slots.default && slots.default()
    )
  )

const isSignInVisible = ref(false)
const isForgotPasswordVisible = ref(false)
const isNewPasswordConfirmationVisible = ref(false)

provide(IS_SIGNIN_DIALOG_VISIBLE_KEY, isSignInVisible)
provide(IS_FORGOT_PASSWORD_VISIBLE_KEY, isForgotPasswordVisible)
provide(
  IS_NEW_PASSWORD_SENT_CONFIRMATION_VISIBLE_KEY,
  isNewPasswordConfirmationVisible
)

const { isMobileAuthPanelOpen, authPanelTabIndex } = toRefs(
  useMobileLayoutStore()
)

function handleTabChange(index: number) {
  authPanelTabIndex.value = index
}

function handleSignInClick() {
  handleTabChange(1)
  isMobileAuthPanelOpen.value = true
}

function handleSignUpClick() {
  handleTabChange(0)
  isMobileAuthPanelOpen.value = true
}

watchEffect(() => {
  if (!isMobileAuthPanelOpen.value) {
    authPanelTabIndex.value = 0
  }
})
</script>

<style scoped>
.tabsContainer {
  display: grid;
  grid-template-rows: auto 1fr;
}

.forgot-password-layout {
  display: grid;
  align-content: center;
}

.forgot-password-confirmation-layout {
  display: grid;
  align-content: center;
}
</style>
