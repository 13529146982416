import { useMediaQuery } from '@vueuse/core'
import { MediaQueryMinWidth } from '@/utils/constants'

export const useMediaQueryFlags = () => {
  const isMobile = useMediaQuery(
    `(max-width: ${MediaQueryMinWidth.TABLET - 1}px)`
  )
  const isTablet = useMediaQuery(
    `(min-width: ${MediaQueryMinWidth.TABLET}px) and (max-width: ${
      MediaQueryMinWidth.DESKTOP_S - 1
    }px)`
  )

  const isDesktopS = useMediaQuery(
    `(min-width: ${MediaQueryMinWidth.DESKTOP_S}px) and (max-width: ${
      MediaQueryMinWidth.DESKTOP - 1
    }px)`
  )

  const isDesktop = useMediaQuery(
    `(min-width: ${MediaQueryMinWidth.DESKTOP}px)`
  )

  return {
    isMobile,
    isTablet,
    isDesktopS,
    isDesktop,
  }
}
