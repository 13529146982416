<template>
  <MobileFullscreenPanel v-model:visible="isMobileMainMenuOpen">
    <div
      class="flex flex-col pt-[40px] gap-[24px] relative h-full overflow-y-auto pb-[24px]"
    >
      <div class="px-[32px]">
        <GamesGlobalSearch ref="searchRef" />
      </div>
      <div class="px-[24px]">
        <MobileMainNavigation />
      </div>
      <div class="px-[24px]">
        <MobileLanguageSelector />
      </div>
    </div>
  </MobileFullscreenPanel>
</template>

<script setup lang="ts">
import MobileMainNavigation from '@/layouts/DefaultLayout/MobileLayout/MobileMainMenu/MobileMainNavigation.vue'
import MobileFullscreenPanel from '@/layouts/DefaultLayout/MobileLayout/MobileFullscreenPanel/MobileFullscreenPanel.vue'
import GamesGlobalSearch from '@/pages/MainPage/GamesCatalog/GamesGlobalSearch.vue'
import { ref, toRefs, watchEffect } from 'vue'
import MobileLanguageSelector from '@/layouts/DefaultLayout/MobileLayout/MobileLanguageSelector.vue'
import { useBodyOverflow } from '@/layouts/DefaultLayout/MobileLayout/useBodyOverflow'
import { useMobileLayoutStore } from '@/stores/mobileLayout'

const { isMobileMainMenuOpen, isMobileProfilePanelOpen, globalSearchRef } =
  toRefs(useMobileLayoutStore())

useBodyOverflow(isMobileMainMenuOpen)

watchEffect(() => {
  if (isMobileMainMenuOpen.value) {
    isMobileProfilePanelOpen.value = false
  }
})

const searchRef = ref<HTMLInputElement | null>(null)

watchEffect(() => {
  globalSearchRef.value = searchRef.value
})
</script>
