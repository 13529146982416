<template>
  <div
    class="z-50 h-[88px] top-0 flex px-6 gap-4 items-center flex-row justify-between w-full flex-1 bg-ne-bg-2"
  >
    <div class="flex flex-1 gap-4 w-full justify-between">
      <AppLogo />
      <template v-if="isSignedIn">
        <WalletSelector class="max-w-[220px] flex-1" />
        <MobileProfilePanel class="ml-auto" />
      </template>
    </div>
    <div v-if="!isSignedIn" class="">
      <MobileAuthPanel />
    </div>
  </div>
</template>

<script setup lang="ts">
import WalletSelector from '@/components/WalletSelector.vue'
import MobileProfilePanel from '@/layouts/DefaultLayout/MobileLayout/MobileAppHeader/MobileProfileMenu/MobileProfilePanel.vue'
import { useAuthStore } from '@/stores/auth'
import { toRefs } from 'vue'
import MobileAuthPanel from '@/layouts/DefaultLayout/MobileLayout/MobileAuthPanel/MobileAuthPanel.vue'
import AppLogo from '@/components/AppLogo.vue'

const { isSignedIn } = toRefs(useAuthStore())
</script>

<style scoped></style>
