import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'

import {
  getProfile,
  GetProfileResponse,
  ProfileWallet,
} from '@/api/actions/getProfile'

import { getNotificationsCount } from '@/api/actions/getNotificationsCount'

import { useLocalStorageReactiveFields } from '@/composables/useLocalStorageReactiveFields'
export type ProfileData = GetProfileResponse

import { avatarUrlsMap as AvatarUrl } from '@/utils/avatarUrlsMap'

const api = {
  getProfile,
  getNotificationsCount,
}

export const useProfileStore = defineStore('profile', () => {
  // persistent data
  const { profileData } = useLocalStorageReactiveFields()

  const profileStatusAvatarUrl = computed(() => {
    return AvatarUrl[profileData.value?.vip.statuses.current.title || 'Opal']
  })

  const isHighestProfileStatus = computed(() => {
    return !profileData.value?.vip.statuses.next
  })

  const profileStatusProgress = computed(() => {
    if (!profileData.value) return null

    // If current status is maximum - there is no progress value
    if (isHighestProfileStatus.value) return null

    const min = profileData.value.vip.statuses.current.minCp
    const max = profileData.value.vip.statuses.current.maxCp!
    const current = profileData.value.vip.cp

    const step = (max - min) / 100
    const dist = Number(current) - min

    const percent = dist / step

    return +percent.toFixed(2)
  })

  const refreshProfile = async () => {
    profileData.value = await api.getProfile()
  }

  const clearProfileData = () => {
    profileData.value = null
  }

  const notificationsCount = ref<number>(0)

  const updateNotificationsCount = async () => {
    const res = await api.getNotificationsCount()
    notificationsCount.value = res.count
  }

  const onSelectedWalletInWalletAndCurrencySelector = (
    wallet: ProfileWallet
  ) => {
    return wallet
  }

  watch(
    () => notificationsCount.value,
    (nv) => {
      if (nv > 0) {
        refreshProfile()
      }
    }
  )

  return {
    profileData,
    isHighestProfileStatus,
    profileStatusProgress,
    profileStatusAvatarUrl,
    refreshProfile,
    clearProfileData,

    notificationsCount,
    updateNotificationsCount,
    onSelectedWalletInWalletAndCurrencySelector,
  }
})
