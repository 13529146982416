import { httpClient, HttpClientResponse } from '@/api/httpClient'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { toCamelCase } from '@/utils/casesConverter.js'

import { ISODateString } from '@/api/utils'
import {
  APiPaginationLinks,
  APiPaginationMeta,
  PaginationLinks,
  PaginationMeta,
} from '@/api/types'

export interface ApiNotificationsListSuccessRequest {
  perPage?: number
  page: number
}
export interface ApiNotificationsListSuccessApiRequest {
  per_page?: number
  page: number
}

export interface ApiNotificationsListSuccessResponse {
  data: {
    id: string
    data: {
      message: string
    }
    sender_type: string
    sender_id: number
    read_at: ISODateString | null
    created_at: ISODateString
    updated_at: ISODateString
  }[]
  links: APiPaginationLinks
  meta: APiPaginationMeta
}

export interface GetNotificationsListResponse {
  data: {
    id: string
    data: {
      message: string
    }
    senderType: string
    senderId: number
    readAt: ISODateString | null
    createdAt: ISODateString
    updatedAt: ISODateString
  }[]
  links: PaginationLinks
  meta: PaginationMeta
}

export const getNotificationsList = async (
  data: ApiNotificationsListSuccessRequest
): Promise<GetNotificationsListResponse> => {
  const params: ApiNotificationsListSuccessApiRequest = {
    page: data.page,
  }

  if (data.perPage) {
    params.per_page = data.perPage
  }

  const response = await httpClient.get<
    ApiNotificationsListSuccessResponse,
    HttpClientResponse<ApiNotificationsListSuccessResponse>,
    ApiNotificationsListSuccessApiRequest
  >('/api/notifications', {
    params,
  })

  // TODO validate response data
  const convertedToCamelCaseResponse = toCamelCase(
    response.data
  ) as GetNotificationsListResponse
  return convertedToCamelCaseResponse
}
