<template>
  <Popover class="relative">
    <PopoverButton
      class="flex items-center justify-center bg-ne-violet w-[36px] h-[36px] rounded-ne-s tablet:hidden"
    >
      <AppIcon name="login-16" color="r-var(--ne-white)" />
    </PopoverButton>

    <transition
      leave-active-class="transition ease-in duration-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <PopoverPanel class="absolute right-0 w-[176px] mt-ne-8 z-10">
        <div
          class="grid grid-cols-1 py-ne-8 justify-start bg-ne-bg-3 backdrop-filter backdrop-blur-lg bg-opacity-60 items-center rounded-lg px-ne-24 py-ne-24 overflow-hidden"
        >
          <AppButton
            class="mb-ne-24"
            view="accent"
            :text="$t('header.login')"
            @click="$emit('login')"
          />
          <AppButton
            view="primary"
            :text="$t('header.sign-up')"
            @click="$emit('signUp')"
          />
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import AppIcon from '../app/AppIcon.vue'
import AppButton from '../app/AppButton.vue'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'

export default defineComponent({
  name: 'LogInPopover',
  components: { Popover, PopoverButton, PopoverPanel, AppIcon, AppButton },
  emits: ['login', 'signUp'],
  // setup() {},
})
</script>
