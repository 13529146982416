<template>
  <div>
    <h2 v-if="isMobileLayout" class="text-ne-h3 font-semibold text-center mb-6">
      {{ $t('dialogs.forgotPassword.title') }}
    </h2>
    <span
      class="block text-ne-neutral text-ne-14 text-center mb-[40px] tablet:my-[75px]"
    >
      {{ $t('dialogs.forgotPasswordRequestSent.annotation') }}
    </span>
    <AppButton
      full
      :text="$t('dialogs.forgotPasswordRequestSent.acceptButton')"
      view="accent"
      class="max-h-[40px]"
      @click="handleAcceptClick"
    />
    <a href="#" class="block mt-ne-32 text-center text-ne-14 text-ne-link mb-2">
      {{ $t('dialogs.forgotPassword.links.unlockInstructions') }}
    </a>
    <a href="#" class="block mt-ne-4 text-center text-ne-14 text-ne-link">
      {{ $t('dialogs.forgotPassword.links.confirmationInstructions') }}
    </a>
  </div>
</template>

<script setup lang="ts">
import AppButton from '@/components/app/AppButton.vue'
import { inject, ref } from 'vue'
import { IS_NEW_PASSWORD_SENT_CONFIRMATION_VISIBLE_KEY } from '@/components/app-authentication/DataContext/IS_NEW_PASSWORD_SENT_CONFIRMATION_VISIBLE_KEY'
import { IS_MOBILE_LAYOUT_KEY } from '@/layouts/DefaultLayout/MobileLayout/DataContext/IS_MOBILE_LAYOUT_KEY'

const isMobileLayout = inject(IS_MOBILE_LAYOUT_KEY, ref(false))
const isOpen = inject(IS_NEW_PASSWORD_SENT_CONFIRMATION_VISIBLE_KEY, ref(false))

function handleAcceptClick() {
  isOpen.value = false
}
</script>
