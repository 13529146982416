import { AxiosError, AxiosResponse } from 'axios'

// import { Brand } from '@/types'

// export type UnixTimestamp = Brand<number, 'UnixTimestamp'>
export type UnixTimestamp = number
export type MillisecondsTimestamp = number

export type { ISODateString, DateTimeString } from '@/types'

export interface ApiCommonSuccessResponse<T> {
  data: T
}

export interface ApiErrorResponse {
  errors?: Record<string, string>
  message: string
  error: string
}

export interface ApiErrorCustom extends AxiosError {
  response: AxiosResponse<ApiErrorResponse>
}

export const isError = (e: unknown): e is Error => {
  return e instanceof Error
}

export const isAxiosError = (e: Error): e is AxiosError => 'isAxiosError' in e

export const isApiError = (
  e: Error | AxiosError<ApiErrorResponse>
): e is ApiErrorCustom => {
  if (!isAxiosError(e)) {
    return false
  }
  return !!e.response?.data
}

export const getRejectedRequestError = (e: unknown): ApiErrorCustom | Error => {
  if (!isError(e)) {
    return new Error('Unknown error')
  }

  if (!isAxiosError(e) || !isApiError(e)) {
    return e
  }

  return e
}

export const getRejectedRequestErrorMessage = (e: unknown): string => {
  const error = getRejectedRequestError(e)
  if (!isApiError(error)) {
    return error.message
  } else if (!error.response.data.errors) {
    return error.response.data.message || error.response.data.error
  } else {
    return Object.values(error.response.data.errors)[0][0]
  }
}
