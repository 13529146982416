import axios, { AxiosResponse } from 'axios'

import { useAuthStore } from '@/stores/auth'
import router, { RoutesNames } from '@/router'

import { useLocalStorageReactiveFields } from '@/composables/useLocalStorageReactiveFields'

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export type HttpClientResponse<T = any, D = any> = AxiosResponse<T, D>

// Set config defaults when creating the instance
const httpClient = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
})

httpClient.interceptors.request.use((config) => {
  const { profileData, selectedLocale } = useLocalStorageReactiveFields()

  if (!config.headers) {
    config.headers = {}
  }

  config.headers['X-Lang'] = profileData.value?.locale || selectedLocale.value

  return config
})

httpClient.interceptors.request.use(async function (config) {
  const authStore = useAuthStore()

  if (!config.headers) {
    config.headers = {}
  }

  const { url, headers } = config
  const { accessToken, refreshToken } = authStore

  // refresh access token if expired
  if (
    authStore.isSignedIn &&
    !authStore.isTokenValid() &&
    !url?.includes('api/auth/resignin') // prevent endless loop
  ) {
    await authStore.refreshAuth()
  }

  if (url?.includes('api/auth/resignin')) {
    headers['Authorization'] = `Bearer ${refreshToken}`
  }

  // add access token for any request to backoffice/api
  if (
    url?.startsWith('/api') &&
    !url?.includes('api/auth/resignin') &&
    headers
  ) {
    headers['Authorization'] = `Bearer ${accessToken}`
  }

  return config
})

httpClient.interceptors.response.use(undefined, async function (error) {
  // clear auth store if unauthorized
  if (error.response?.status === 401) {
    useAuthStore().clear()
    router.push({ name: RoutesNames.Index })
  }

  return Promise.reject(error)
})

export { httpClient }
