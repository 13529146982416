<template>
  <div class="bg-ne-bg-5 rounded-[4px] min-h-[20px] w-full animate-pulse"></div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SkeletonBone',
})
</script>
