<template>
  <vue-svg-icon role="img" :data="svg" original v-bind="$attrs" />
</template>

<script setup lang="ts">
import { computed } from 'vue'

interface AppIconProps {
  name: string
}

const props = defineProps<AppIconProps>()

const globIcons = import.meta.globEager(`../../assets/svg-icons/*.svg`)
const svg = computed(
  () => globIcons[`../../assets/svg-icons/${props.name}.svg`].default
)
</script>
