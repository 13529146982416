<template>
  <MobileFullscreenPanel
    v-model:visible="isMobileDepositPanelOpen"
    transition="bottomToTop"
    type="cross"
  >
    <div class="px-[24px] pb-[24px] overflow-y-auto h-full">
      <DepositWalletPage />
    </div>
  </MobileFullscreenPanel>
</template>

<script setup lang="ts">
import MobileFullscreenPanel from '@/layouts/DefaultLayout/MobileLayout/MobileFullscreenPanel/MobileFullscreenPanel.vue'
import DepositWalletPage from '@/pages/ProfilePage/child-pages/WalletPage/child-pages/DepositWalletPage.vue'
import { toRefs } from 'vue'
import { useMobileLayoutStore } from '@/stores/mobileLayout'

const { isMobileDepositPanelOpen } = toRefs(useMobileLayoutStore())
</script>
