<template>
  <div :class="{ 'opacity-40 pointer-events-none': disabled }">
    <input
      v-bind="{ ...$attrs }"
      :id="name"
      :name="name"
      class="app-radio"
      type="radio"
      :checked="modelValue === value"
      :disabled="disabled"
      @change="$emit('update:modelValue', value)"
    />
    <label :for="name" class="cursor-pointer flex items-center">
      <span class="app-radio__custom-radio shrink-0 mr-ne-8"></span>
      <span
        v-if="label"
        :id="`${name}-error`"
        class="text-ne-14"
        :class="{
          'text-ne-white': !light,
          'text-ne-black': light,
        }"
      >
        {{ label }}
      </span>
    </label>
    <span v-if="error">
      {{ error }}
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'AppRadio',

  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number] as PropType<string | number | null>,
      default: null,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    error: {
      type: String,
      default: '',
    },
    light: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  setup() {
    //
  },
})
</script>
