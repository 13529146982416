// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export function SetupFormComponent(props, { emit }) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const updateValue = (event) => {
    let val = event.target.value

    if (event.target.type === 'checkbox') val = event.target.checked
    if (event.target.type === 'radio') val = props.value

    emit('update:modelValue', val)
  }

  return { updateValue }
}
