import { storeToRefs } from 'pinia'
import { useAuthStore } from '@/stores/auth'
import { useProfileStore } from '@/stores/profileStore'
import { updateProfile } from '@/api/actions/updateProfile'
import { useLocalStorageReactiveFields } from '@/composables/useLocalStorageReactiveFields'
import { useI18n } from 'vue-i18n'

export function useLanguageSelector() {
  const { locale } = useI18n()
  const { isSignedIn } = storeToRefs(useAuthStore())
  const { refreshProfile } = useProfileStore()
  const { selectedLocale } = useLocalStorageReactiveFields()

  async function handleLocaleChange(value: string) {
    value = value.toLowerCase()
    locale.value = value
    selectedLocale.value = value

    if (isSignedIn.value) {
      await updateProfile({
        locale: selectedLocale.value,
      })
      await refreshProfile()
    }
  }

  return {
    handleLocaleChange,
  }
}
