import { httpClient, HttpClientResponse } from '@/api/httpClient'

import { ApiCommonSuccessResponse } from '@/api/utils'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { toCamelCase } from '@/utils/casesConverter.js'

export type ApplyPromoApiRequest = FormData

export type ApplyPromoApiResponse = {
  success: boolean
}

export type ApplyPromoResponse = {
  success: boolean
}

export const applyPromo = async (
  promo: string,
  token?: string,
  forcePriorityCurrency?: string
): Promise<ApplyPromoResponse> => {
  const formData = new FormData()
  formData.append('code', promo)

  if (token) {
    formData.append('token', token)
  }

  if (forcePriorityCurrency) {
    formData.append('force_priority', '1')
    formData.append('force_priority_currency', forcePriorityCurrency)
  }

  const response = await httpClient.post<
    ApplyPromoApiResponse,
    HttpClientResponse<ApiCommonSuccessResponse<ApplyPromoApiResponse>>,
    ApplyPromoApiRequest
  >('/api/bonuses/bonus-code', formData)

  // TODO validate response data
  const convertedToCamelCaseResponse = toCamelCase(
    response.data
  ) as ApplyPromoResponse

  return convertedToCamelCaseResponse
}
