<template>
  <AppIcon class="animate-spin" name="loading-16" :color="color" />
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue'

import AppIcon from './AppIcon.vue'

export default defineComponent({
  name: 'AppSpinner',

  components: {
    AppIcon,
  },

  props: {
    theme: {
      type: String as PropType<'violet' | 'white'>,
      default: 'white',
    },
  },

  setup(props) {
    const color = computed(() => {
      if (props.theme === 'violet') {
        return 'var(--ne-violet-light)'
      }

      return 'var(--ne-white)'
    })
    return {
      color,
    }
  },
})
</script>
