const CURRENCY_CODE_TO_SIGN: Record<string, string> = {
  USD: '$',
  EUR: '€',
}

export function formatAmountInCurrency(
  amount: number,
  currency: string
): string {
  const currencySign = CURRENCY_CODE_TO_SIGN[currency]
  return `${currencySign || currency} ${amount}`
}
