import { httpClient, HttpClientResponse } from '@/api/httpClient'

import { ApiCommonSuccessResponse } from '@/api/utils'

export interface ForgotPasswordData {
  email: string
}

export interface ForgotPasswordApiRequest {
  email: string
}

interface ForgotPasswordApiResponse {
  success: boolean
  message: string
}

interface ForgotPasswordResponse {
  success: boolean
  message: string
}

export const forgotPassword = async ({
  email,
}: ForgotPasswordData): Promise<ForgotPasswordResponse> => {
  const response = await httpClient.post<
    ForgotPasswordApiResponse,
    HttpClientResponse<ApiCommonSuccessResponse<ForgotPasswordApiResponse>>,
    ForgotPasswordApiRequest
  >('/api/auth/forgot-password', {
    email,
  })
  return response.data.data
}
