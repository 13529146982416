import { httpClient, HttpClientResponse } from '@/api/httpClient'

import { ApiCommonSuccessResponse } from '@/api/utils'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { toCamelCase } from '@/utils/casesConverter.js'

export interface ForcePriorityApiRequest {
  bonus_item_id: number
  currency: string
}
export interface ForcePriorityRequest {
  bonusItemId: number
  currency: string
}

export type ForcePriorityApiResponse = {
  success: boolean
}

export type ForcePriorityResponse = {
  success: boolean
}

export const forcePriority = async (
  bonusItemId: number,
  currency: string
): Promise<ForcePriorityResponse> => {
  const response = await httpClient.post<
    ForcePriorityApiResponse,
    HttpClientResponse<ApiCommonSuccessResponse<ForcePriorityApiResponse>>,
    ForcePriorityApiRequest
  >(`/api/bonuses/force-priority`, {
    bonus_item_id: bonusItemId,
    currency: currency,
  })

  // TODO validate response data
  const convertedToCamelCaseResponse = toCamelCase(
    response.data
  ) as ForcePriorityResponse

  return convertedToCamelCaseResponse
}
