<template>
  <div
    class="flex flex-col py-ne-32 tablet:py-ne-40 items-center text-ne-white text-center"
    :class="{
      'bg-ne-bg-2': !dark,
      'bg-ne-bg-1': dark,
    }"
  >
    <div class="ne-container tablet:flex flex-wrap">
      <div class="tablet:flex tablet:justify-between tablet:w-full">
        <div
          class="mb-[64px] tablet:mr-[54px] desktop:w-[485px] desktop:flex desktop:mb-0 desktop:flex-col"
        >
          <div
            class="flex flex-col desktop-s:flex-row items-center tablet:items-start desktop-s:items-center justify-center desktop-s:justify-start desktop-s:mb-ne-40"
          >
            <router-link
              :to="{ name: RoutesNames.Index }"
              @click="handleLogoClick"
            >
              <AppIcon
                name="main_logo"
                class="w-[180px] h-[37px] mb-ne-24 desktop-s:mb-0 desktop-s:mr-ne-32"
              ></AppIcon>
            </router-link>
          </div>
          <div class="text-ne-14 text-left hidden desktop:block">
            <p class="block mb-ne-24">
              {{ $t('footer.text1') }}
            </p>
            <p>
              {{ $t('footer.text2') }}
            </p>
          </div>
        </div>
        <div
          class="flex flex-col tablet:pt-ne-10 tablet:self-end tablet:flex-row"
        >
          <div
            class="flex flex-col tablet:text-left tablet:max-w-[130px] tablet:min-w-[110px] tablet:mr-[40px]"
          >
            <h1 class="text-ne-h3 font-bold text-ne-violet mb-ne-24">
              {{ $t('footer.title.about') }}
            </h1>
            <div class="flex flex-col text-ne-14 mb-ne-24">
              <router-link class="mb-[20px]" :to="{ name: RoutesNames.Index }">
                <span>{{ $t('footer.navigation.all-games') }}</span>
              </router-link>
              <!--              <a href="#" class="mb-[20px]">{{-->
              <!--                $t('footer.navigation.promotions')-->
              <!--              }}</a>-->
              <router-link
                class="mb-[20px]"
                :to="{ name: RoutesNames.AboutUs }"
              >
                <span>{{ $t('footer.navigation.about') }}</span>
              </router-link>
            </div>
          </div>
          <div
            class="flex flex-col tablet:text-left tablet:min-w-[110px] tablet:max-w-[130px] tablet:mr-[40px]"
          >
            <h1 class="text-ne-h3 font-bold text-ne-violet mb-ne-24">
              {{ $t('footer.title.help') }}
            </h1>
            <div class="flex flex-col text-ne-14 mb-ne-24">
              <router-link
                class="mb-[20px]"
                :to="{ name: RoutesNames.Support }"
              >
                <span>{{ $t('footer.navigation.support') }}</span>
              </router-link>
              <router-link class="mb-[20px]" :to="{ name: RoutesNames.Faq }">
                <span>{{ $t('footer.navigation.faq') }}</span>
              </router-link>
              <router-link
                class="mb-[20px]"
                :to="{ name: RoutesNames.ResponsibleGaming }"
              >
                <span>{{ $t('footer.navigation.responsible') }}</span>
              </router-link>
            </div>
          </div>
          <div
            class="flex flex-col tablet:text-left tablet:min-w-[110px] tablet:max-w-[130px]"
          >
            <h1 class="text-ne-h3 font-bold text-ne-violet mb-ne-24">
              {{ $t('footer.title.info') }}
            </h1>
            <div class="flex flex-col text-ne-14 mb-ne-24">
              <router-link
                class="mb-[20px]"
                :to="{ name: RoutesNames.TermsAndConditions }"
              >
                <span>{{ $t('footer.navigation.terms') }}</span>
              </router-link>
              <router-link
                class="mb-[20px]"
                :to="{ name: RoutesNames.PrivacyPolicy }"
              >
                <span>{{ $t('footer.navigation.privacy-policy') }}</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="text-ne-14 text-left block desktop:hidden mb-ne-16">
          <p class="block mb-ne-24">
            {{ $t('footer.text1') }}
          </p>
          <p>
            {{ $t('footer.text2') }}
          </p>
        </div>

        <div class="flex flex-col">
          <div class="flex mb-ne-16 desktop:mb-ne-24">
            <div
              class="sm:w-[60px] sm:h-[60px] w-[48px] h-[48px] shrink-0 mr-ne-8 sm:mr-ne-16"
            >
              <!--#region Antillephone License Validation-->
              <LicenseInformation />
              <!--#endregion Antillephone License Validation-->
            </div>
            <div class="flex items-center shrink-0">
              <AppIcon name="icon-18-plus" class="w-[40px] h-[40px]" />
            </div>
            <a 
              href="https://cert.gcb.cw/certificate?id=ZXlKcGRpSTZJazB2VFhCNFZtUXlRVXBwUW5Obk5pdFNielZvT1VFOVBTSXNJblpoYkhWbElqb2liV1ZaWml0cmFrVkNNa05GZVRSNlJXRkNlbTVFWnowOUlpd2liV0ZqSWpvaU5tRmlZak5pT0RFeU1HWXhNVFUzTnpabFpXSXhNMkpqWXpRd05HSTFOemM1TkdaalptSXdZVE5tT0Roak9HVmlZVE5rWVdSa05UazRaR1JtTkdWbE9TSXNJblJoWnlJNklpSjk=" 
              class="flex items-center shrink-0"
            >
              <img src="https://seal.cgcb.info/8b089e55-4795-425a-6bf6-fdb75eba6900" class="h-[40px] w-auto" />
            </a>
          </div>
          <p
            class="text-ne-14 text-left text-ne-white text-opacity-30 block"
            v-html="$t('footer.text3')"
          ></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import { RoutesNames } from '@/router'
import AppIcon from '@/components/app/AppIcon.vue'
import LicenseInformation from '@/components/AppFooter/LicenseInformation.vue'

export default defineComponent({
  name: 'AppFooter',

  components: {
    AppIcon,
    LicenseInformation,
  },

  props: {
    dark: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const route = useRoute()

    const handleLogoClick = () => {
      if (route.name === RoutesNames.Index) {
        window.scrollTo(0, 0)
      }
    }

    return {
      handleLogoClick,
      RoutesNames,
    }
  },
})
</script>
