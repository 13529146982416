import { httpClient, HttpClientResponse } from '@/api/httpClient'

import { ApiCommonSuccessResponse } from '@/api/utils'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { toCamelCase } from '@/utils/casesConverter.js'

export interface SetDefaultWalletApiRequest {
  code: string
}

export type SetDefaultWalletApiResponse = {
  success: boolean
}

export type SetDefaultWalletResponse = {
  success: boolean
}

export const setDefaultWallet = async (
  currencyCode: string
): Promise<SetDefaultWalletResponse> => {
  const response = await httpClient.post<
    SetDefaultWalletApiResponse,
    HttpClientResponse<ApiCommonSuccessResponse<SetDefaultWalletApiResponse>>,
    SetDefaultWalletApiRequest
  >(`/api/wallet/default`, {
    code: currencyCode,
  })

  // TODO validate response data
  const convertedToCamelCaseResponse = toCamelCase(
    response.data
  ) as SetDefaultWalletResponse

  return convertedToCamelCaseResponse
}
