function isObject(value) {
  return typeof value === 'object' && value !== null && !Array.isArray(value)
}

function capitalize(input) {
  return input[0].toUpperCase() + input.slice(1)
}

function snakeToCamelCase(input) {
  let result = ''
  const splittedString = input.split('_')

  result = splittedString.shift()
  splittedString.forEach((item) => {
    result += capitalize(item)
  })

  return result
}

function camelToSnakeCase(input) {
  return input
    .split(/(?=[A-Z])/)
    .map((x) => x.toLowerCase())
    .join('_')
}

function convertObject(obj, convertString) {
  const result = {}

  Object.entries(obj).forEach(([key, value]) => {
    const isObjectOrArray = typeof value === 'object' && value !== null
    // eslint-disable-next-line
    result[convertString(key)] = isObjectOrArray
      ? convertCase(value, convertString)
      : value
  })

  return result
}

function convertArray(arr, convertString) {
  // eslint-disable-next-line
  return arr.map((item) =>
    isObject(item) ? convertCase(item, convertString) : item
  )
}

function convertCase(input, convertString) {
  const inputType = typeof input

  switch (inputType) {
    case 'string':
      return convertString(input)
    case 'object':
      if (input === null) {
        return null
      }

      if (Array.isArray(input)) {
        return convertArray(input, convertString)
      }

      return convertObject(input, convertString)
    default:
      return input
  }
}

export function toCamelCase(input) {
  return convertCase(input, snakeToCamelCase)
}

export function toSnakeCase(input) {
  return convertCase(input, camelToSnakeCase)
}
