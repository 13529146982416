// import { PaymentMethodItem } from './shared/WalletAndCurrencySelector.vue'
import { PaymentMethodCode } from '@/api/types'
export const PaymentMethodName: Record<PaymentMethodCode, string> = {
  BANK: 'Bank card',
  BTC: 'BTC',
  ETH: 'ETH',
  TRX_USDT_S2UZ: 'USDT TRC',
  USDT_ERC20: 'USDT ERC',
  LTC: 'LTC',
  USDC: 'USDC ERC',
  TRX_USDC_6NU3: 'USDC TRC',
  PIASTRIX_RUB: 'Piastrix RUB',
}

export const NetworkNames: Record<PaymentMethodCode, string> = {
  BANK: 'Bank card',
  BTC: 'Bitcoin (BTC)',
  ETH: 'Ethereum (ERC20)',
  TRX_USDT_S2UZ: 'TRON (TRC20)',
  USDT_ERC20: 'USDT ERC',
  LTC: 'Litecoin (LTC)',
  USDC: 'Ethereum (ERC20)',
  TRX_USDC_6NU3: 'TRON (TRC20)',
  PIASTRIX_RUB: 'Piastrix RUB',
}

export const NonCryptoNetworks: Array<PaymentMethodCode> = [
  'BANK',
  'PIASTRIX_RUB',
]

export const DecimalPlacesByCurrencyName: Record<string, number> = {
  RUB: 2,
  USD: 2,
  EUR: 2,
}

// export const paymentMethods: PaymentMethodItem[] = [
//   {
//     name: 'Bank card',
//     value: 'BANK',
//   },
//   {
//     name: 'BTC',
//     value: 'BTC',
//   },
//   {
//     name: 'ETH',
//     value: 'ETH',
//   },
//   {
//     name: 'USDT TRC',
//     value: 'TRX_USDT_S2UZ',
//   },
//   {
//     name: 'USDT ERC',
//     value: 'USDT_ERC20',
//   },
//   {
//     name: 'LTC',
//     value: 'LTC',
//   },
//   {
//     name: 'USDC ERC',
//     value: 'USDC',
//   },
//   {
//     name: 'USDC TRC',
//     value: 'TRX_USDC_6NU3',
//   },
// ]
