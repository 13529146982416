<template>
  <Popover class="relative mr-ne-16 tablet:mr-ne-24">
    <PopoverButton class="flex-nowrap items-center flex">
      <AppButton class="rounded-full p-0 w-[40px] relative">
        <div
          v-if="profileStore.notificationsCount"
          class="bg-ne-error rounded-ne-m px-ne-4 absolute top-0 right-0 translate-x-ne-4 text-ne-10"
        >
          {{ profileStore.notificationsCount }}
        </div>
        <AppIcon name="bell-16" color="r-var(--ne-white)" />
      </AppButton>
    </PopoverButton>

    <transition
      leave-active-class="transition ease-in duration-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <PopoverPanel
        v-slot="{ close }"
        class="absolute top-[60px] -left-[230px] tablet-s:-left-[153px] w-[360px] z-10"
      >
        <div
          ref="popoverPanelContentRef"
          class="grid grid-cols-1 justify-start bg-ne-bg-4 p-ne-24 items-center rounded-ne-s overflow-hidden firefox:bg-opacity-60"
        >
          <template v-if="isLoading">
            <SkeletonBone
              v-for="num of 3"
              :key="num"
              class="w-full h-[44px] mb-ne-24"
            />
          </template>
          <template
            v-else-if="!notificationsList || notificationsList.length === 0"
          >
            {{ $t('header.no-notifications') }}
          </template>
          <template v-else>
            <div
              v-for="(notification, index) of notificationsList"
              :key="index"
              class="rounded-ne-m mb-ne-32"
              :class="{
                'text-ne-white': !notification.readAt,
                'text-ne-neutral': notification.readAt,
              }"
            >
              <div class="flex relative mb-ne-16">
                <h3
                  class="flex flex-start text-ne-14 font-bold leading-[16px] w-full"
                >
                  {{ convertDate(notification.createdAt) }}
                </h3>
                <AppBadge
                  class="text-ne-white absolute -right-ne-8 -top-ne-4"
                  :type="notification.readAt ? 'neutral' : 'error'"
                  >{{ notification.readAt ? 'Read' : 'New' }}</AppBadge
                >
              </div>
              <p class="flex flex-start text-start text-ne-14">
                {{ notification.data.message }}
              </p>
            </div>
          </template>

          <router-link
            :to="{ name: RoutesNames.ProfileNotifications }"
            @click="close"
          >
            <AppButton :text="$t('header.show-all')" />
          </router-link>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import AppIcon from '@/components/app/AppIcon.vue'
import AppButton from '@/components/app/AppButton.vue'
import AppBadge from '@/components/app/AppBadge.vue'
import SkeletonBone from '@/components/SkeletonBone.vue'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { RoutesNames } from '@/router'
import { useProfileStore } from '@/stores/profileStore'

import {
  getNotificationsList as getNotificationsListAction,
  GetNotificationsListResponse,
} from '@/api/actions/getNotificationsList'
import { ISODateString } from '@/api/utils'
import { DateTime } from 'luxon'

type Notification = GetNotificationsListResponse['data'][number]

const api = {
  getNotificationsList: getNotificationsListAction,
}

export default defineComponent({
  name: 'NotificationPopover',
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    AppIcon,
    AppBadge,
    AppButton,
    SkeletonBone,
  },

  setup() {
    const profileStore = useProfileStore()

    const isLoading = ref<boolean>(false)
    const notificationsList = ref<Notification[] | null>(null)

    const convertDate = (date: ISODateString) => {
      return DateTime.fromISO(date).toFormat('d LLLL, HH:mm')
    }
    const getNotificationsList = async () => {
      isLoading.value = true
      try {
        const response = await api.getNotificationsList({ page: 1, perPage: 3 })
        notificationsList.value = response.data.slice(0, 3) // TODO: drop slice
      } catch (e) {
        //
      } finally {
        isLoading.value = false
      }
    }

    const popoverPanelContentRef = ref<HTMLDivElement | null>(null)
    const isPopoverPanelVisible = computed(
      () => !!popoverPanelContentRef.value?.innerHTML
    )

    watch(isPopoverPanelVisible, () => {
      const isNotificationsListNotLoaded =
        isPopoverPanelVisible.value && !notificationsList.value
      const isNewNotificationsAvailable = profileStore.notificationsCount > 0

      if (isNotificationsListNotLoaded || isNewNotificationsAvailable) {
        getNotificationsList()
      }
    })

    return {
      RoutesNames,
      profileStore,

      isLoading,

      notificationsList,
      getNotificationsList,
      convertDate,

      popoverPanelContentRef,
    }
  },
})
</script>
