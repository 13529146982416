<template>
  <div ref="rootRef" class="min-h-screen flex flex-col">
    <AppHeader
      v-if="isHeaderVisible"
      ref="appHeaderRef"
      :sticky="!isTargetVisible"
    />
    <div ref="targetRef"></div>
    <div
      class="flex-grow flex flex-col"
      :style="{
        marginTop: isTargetVisible ? undefined : `${headerHeight}px`,
      }"
    >
      <slot></slot>
    </div>
    <AppFooter v-if="isFooterVisible" :dark="darkFooter" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, provide, ref } from 'vue'
import { useIntersectionObserver } from '@vueuse/core'

import AppHeader from '../../../components/AppHeader/index.vue'
import AppFooter from '../../../components/AppFooter/index.vue'
import { useMediaQueryFlags } from '@/composables/useMediaQueryFlags'
import { IS_DESKTOP_LAYOUT_KEY } from '@/layouts/DefaultLayout/DesktopLayout/DataContext/IS_DESKTOP_LAYOUT_KEY'

export default defineComponent({
  components: {
    AppHeader,
    AppFooter,
  },
  props: {
    noHeader: {
      type: Boolean,
      default: false,
    },
    noFooter: {
      type: Boolean,
      default: false,
    },
    isGame: {
      type: Boolean,
      default: false,
    },
    darkFooter: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { isMobile, isTablet, isDesktopS } = useMediaQueryFlags()

    provide(IS_DESKTOP_LAYOUT_KEY, ref(true))

    const rootRef = ref(null)
    const targetRef = ref(null)
    const appHeaderRef = ref<InstanceType<typeof AppHeader> | null>(null)
    const isTargetVisible = ref(false)

    const headerHeight = computed(() => {
      return appHeaderRef.value?.$el.clientHeight
    })

    const isHeaderVisible = computed(() => {
      if (props.isGame) {
        if (isMobile.value || isTablet.value) {
          return false
        }
      }

      if (props.noHeader) {
        return false
      }

      return true
    })

    const isFooterVisible = computed(() => {
      if (props.noFooter) {
        return false
      }

      if (props.isGame) {
        if (isMobile.value || isTablet.value) {
          return false
        }
      }

      return true
    })

    useIntersectionObserver(targetRef, ([{ isIntersecting }]) => {
      isTargetVisible.value = isIntersecting
    })

    return {
      isMobile,
      isTablet,
      isDesktopS,
      rootRef,
      targetRef,
      appHeaderRef,
      isTargetVisible,
      headerHeight,
      isHeaderVisible,
      isFooterVisible,
    }
  },
})
</script>
