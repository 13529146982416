<template>
  <button
    class="flex-nowrap items-center flex bg-ne-bg-4 relative rounded-ne-s"
  >
    <span
      v-if="notificationsCount"
      class="bg-ne-error rounded-ne-m px-ne-4 absolute top-0 right-0 translate-x-ne-4 text-ne-10"
    >
      {{ shownNotificationsCount }}
    </span>
    <span class="w-[44px] h-[44px] flex items-center justify-center">
      <img class="w-full" alt="avatar" :src="profileStatusAvatarUrl" />
    </span>
  </button>
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue'
import { useProfileStore } from '@/stores/profileStore'

const { profileStatusAvatarUrl, notificationsCount } = toRefs(useProfileStore())

const shownNotificationsCount = computed(() =>
  notificationsCount.value >= 100 ? '99+' : notificationsCount.value
)
</script>
