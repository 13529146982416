import { ref, computed } from 'vue'

import {
  getAvailableBonusesList,
  AvailableBonusItem,
} from '@/api/actions/getAvailableBonuses'
import { transformBonusesList } from '../transformers/transform-bonuses-list'

export const useBonusesAvailable = () => {
  const isLoading = ref(false)
  const bonusesList = ref<AvailableBonusItem[]>([])

  const getBonusesList = async (currency?: string) => {
    isLoading.value = true
    try {
      const response = await getAvailableBonusesList({ onlyNext: 1, currency })
      bonusesList.value = [...response.data]
    } catch (e) {
      //
    } finally {
      isLoading.value = false
    }
  }

  const bonusesListTransformed = computed(() =>
    transformBonusesList(bonusesList.value)
  )

  return {
    bonusesList,
    bonusesListTransformed,
    isLoading,
    getBonusesList,
  }
}
