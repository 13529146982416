<template>
  <div>
    <h2 v-if="isMobileLayout" class="text-ne-h3 font-semibold text-center mb-6">
      {{ $t('dialogs.forgotPassword.title') }}
    </h2>
    <span class="block text-ne-neutral text-ne-14 text-center mb-ne-24">
      {{ $t('dialogs.forgotPassword.annotation') }}
    </span>
    <ForgotPasswordForm
      :loading="isForgotPasswordFormLoading"
      :error-message="forgotPasswordFormErrorMessage"
      :submit-text="$t('dialogs.forgotPassword.submitButton')"
      @submit="onSubmitForgotPasswordForm"
    />
  </div>
</template>

<script setup lang="ts">
import { EmittedSingUpData } from '@/components/forms/SignUpForm.vue'
import { inject, ref } from 'vue'
import { getRejectedRequestErrorMessage } from '@/api/utils'
import { forgotPassword } from '@/api/actions/forgotPassword'
import { IS_FORGOT_PASSWORD_VISIBLE_KEY } from '@/components/app-authentication/DataContext/IS_FORGOT_PASSWORD_VISIBLE_KEY'
import ForgotPasswordForm from '@/components/forms/ForgotPasswordForm.vue'
import { IS_NEW_PASSWORD_SENT_CONFIRMATION_VISIBLE_KEY } from '@/components/app-authentication/DataContext/IS_NEW_PASSWORD_SENT_CONFIRMATION_VISIBLE_KEY'
import { IS_MOBILE_LAYOUT_KEY } from '@/layouts/DefaultLayout/MobileLayout/DataContext/IS_MOBILE_LAYOUT_KEY'

const isMobileLayout = inject(IS_MOBILE_LAYOUT_KEY, ref(false))
const isOpen = inject(IS_FORGOT_PASSWORD_VISIBLE_KEY, ref(false))
const isPasswordConfirmationDialogOpen = inject(
  IS_NEW_PASSWORD_SENT_CONFIRMATION_VISIBLE_KEY,
  ref(false)
)
const isForgotPasswordFormLoading = ref<boolean>(false)
const forgotPasswordFormErrorMessage = ref<string | null>(null)

const onSubmitForgotPasswordForm = async (data: EmittedSingUpData) => {
  isForgotPasswordFormLoading.value = true

  try {
    const result = await forgotPassword(data)

    if (result.success) {
      forgotPasswordFormErrorMessage.value = null
      isForgotPasswordFormLoading.value = false
      isOpen.value = false
      isPasswordConfirmationDialogOpen.value = true
    } else {
      forgotPasswordFormErrorMessage.value = result.message
    }
  } catch (e) {
    forgotPasswordFormErrorMessage.value = getRejectedRequestErrorMessage(e)
  } finally {
    isForgotPasswordFormLoading.value = false
  }
}
</script>
