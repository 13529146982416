import { httpClient, HttpClientResponse } from '@/api/httpClient'

import { ApiCommonSuccessResponse } from '@/api/utils'

interface GetNotificationsCountApiResponse {
  count: number
}

interface GetInitialDatResponse {
  count: number
}

export const getNotificationsCount =
  async (): Promise<GetInitialDatResponse> => {
    const response = await httpClient.get<
      GetNotificationsCountApiResponse,
      HttpClientResponse<
        ApiCommonSuccessResponse<GetNotificationsCountApiResponse>
      >
    >('/api/notifications/count-unread')

    // TODO validate response data
    return response.data.data
  }
