import { httpClient, HttpClientResponse } from '@/api/httpClient'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { toCamelCase } from '@/utils/casesConverter.js'

import { ApiCommonSuccessResponse } from '@/api/utils'

export interface GetCountriesDisplayApiResponse {
  id: number
  code: string
  name: string
  available_for_display: boolean
}

export interface GetCountriesDisplayResponse {
  id: number
  code: string
  name: string
  availableForDisplay: boolean
}

export const getCountriesDisplay =
  async (): Promise<GetCountriesDisplayResponse> => {
    const response = await httpClient.get<
      GetCountriesDisplayApiResponse,
      HttpClientResponse<
        ApiCommonSuccessResponse<GetCountriesDisplayApiResponse>
      >
    >('/api/countries/check-display')

    // // TODO validate response data
    const convertedToCamelCaseResponse = toCamelCase(
      response.data.data
    ) as GetCountriesDisplayResponse

    return convertedToCamelCaseResponse
  }
