import { httpClient, HttpClientResponse } from '@/api/httpClient'

import { ApiCommonSuccessResponse } from '@/api/utils'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { toCamelCase } from '@/utils/casesConverter.js'

export interface CheckGoogle2faOtpRequest {
  oneTimePassword: string
}

export interface CheckGoogle2faOtpApiRequest {
  one_time_password: string
}

export type CheckGoogle2faOtpApiResponse = {
  secret_key: string
  qr_code: string
}

export type CheckGoogle2faOtpResponse = {
  secretKey: string
  qrCode: string
}

export const checkGoogle2faOtp = async ({
  oneTimePassword,
}: CheckGoogle2faOtpRequest): Promise<CheckGoogle2faOtpResponse> => {
  const response = await httpClient.post<
    CheckGoogle2faOtpApiResponse,
    HttpClientResponse<ApiCommonSuccessResponse<CheckGoogle2faOtpApiResponse>>,
    CheckGoogle2faOtpApiRequest
  >(`/api/google2fa/check-otp`, { one_time_password: oneTimePassword })

  // TODO validate response data
  const convertedToCamelCaseResponse = toCamelCase(
    response.data.data
  ) as CheckGoogle2faOtpResponse

  return convertedToCamelCaseResponse
}
