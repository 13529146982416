<template>
  <MobileProfileButton @click="isMobileProfilePanelOpen = true" />
  <MobileFullscreenPanel
    v-model:visible="isMobileProfilePanelOpen"
    transition="rightToLeft"
    type="cross"
  >
    <div class="px-[24px] h-full overflow-y-auto pb-[24px]">
      <MobileProfileProgress />
      <MobileProfileNavigation class="pt-6 overflow-y-auto" />
    </div>
  </MobileFullscreenPanel>
</template>

<script setup lang="ts">
import { toRefs } from 'vue'
import MobileFullscreenPanel from '@/layouts/DefaultLayout/MobileLayout/MobileFullscreenPanel/MobileFullscreenPanel.vue'
import MobileProfileNavigation from '@/layouts/DefaultLayout/MobileLayout/MobileAppHeader/MobileProfileMenu/MobileProfileNavigation.vue'
import MobileProfileProgress from '@/layouts/DefaultLayout/MobileLayout/MobileAppHeader/MobileProfileMenu/MobileProfileProgress.vue'
import { useBodyOverflow } from '@/layouts/DefaultLayout/MobileLayout/useBodyOverflow'
import MobileProfileButton from '@/layouts/DefaultLayout/MobileLayout/MobileAppHeader/MobileProfileMenu/MobileProfileButton.vue'
import { useMobileLayoutStore } from '@/stores/mobileLayout'

const { isMobileProfilePanelOpen } = toRefs(useMobileLayoutStore())

useBodyOverflow(isMobileProfilePanelOpen)
</script>
