import { httpClient, HttpClientResponse } from '@/api/httpClient'

import { ApiCommonSuccessResponse } from '@/api/utils'

interface SignOutApiResponse {
  success: true
}

interface SignOutResponse {
  success: true
}

export const signOut = async (): Promise<SignOutResponse> => {
  const response = await httpClient.post<
    SignOutApiResponse,
    HttpClientResponse<ApiCommonSuccessResponse<SignOutApiResponse>>
  >('/api/auth/signout')
  return response.data.data
}
