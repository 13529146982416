import { VipStatusName } from '@/types'
import opalAvatarImg from '@/assets/images/vip-statuses-logos/opal.png'
import sapphireAvatarImg from '@/assets/images/vip-statuses-logos/vector-sapphire.png'
import emeraldAvatarImg from '@/assets/images/vip-statuses-logos/emerald.png'
import rubyAvatarImg from '@/assets/images/vip-statuses-logos/vector-ruby.png'
import diamondAvatarImg from '@/assets/images/vip-statuses-logos/diamond.png'
import cryptonitAvatarImg from '@/assets/images/vip-statuses-logos/cryptonit.png'
import crystalAvatarImg from '@/assets/images/vip-statuses-logos/vector-crystal.png'
import zirconAvatarImg from '@/assets/images/vip-statuses-logos/vector-zircon.png'
import garnetAvatarImg from '@/assets/images/vip-statuses-logos/vector-garnet.png'
import onyxAvatarImg from '@/assets/images/vip-statuses-logos/vector-onyx.png'
import topazAvatarImg from '@/assets/images/vip-statuses-logos/vector-topaz.png'
import pyriteAvatarImg from '@/assets/images/vip-statuses-logos/vector-pyrite.png'
import citrineAvatarImg from '@/assets/images/vip-statuses-logos/vector-citrine.png'
import alexandriteAvatarImg from '@/assets/images/vip-statuses-logos/vector-alexandrite.png'

export const avatarUrlsMap: Record<VipStatusName, string> = {
  Opal: opalAvatarImg,
  Sapphire: sapphireAvatarImg,
  Emerald: emeraldAvatarImg,
  Ruby: rubyAvatarImg,
  Diamond: diamondAvatarImg,
  Cryptonit: cryptonitAvatarImg,
  Crystal: crystalAvatarImg,
  Zircon: zirconAvatarImg,
  Garnet: garnetAvatarImg,
  Onyx: onyxAvatarImg,
  Topaz: topazAvatarImg,
  Pyrite: pyriteAvatarImg,
  Citrine: citrineAvatarImg,
  Alexandrite: alexandriteAvatarImg,
}
