import { httpClient, HttpClientResponse } from '@/api/httpClient'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { toSnakeCase } from '@/utils/casesConverter.js'

import { ApiCommonSuccessResponse } from '@/api/utils'

import {
  GetProfileApiResponse,
  GetProfileResponse,
} from '@/api/actions/getProfile'

interface UpdateProfileApiRequest extends Partial<GetProfileApiResponse> {
  disabled?: 1 | 0
}
interface UpdateProfileRequest extends Partial<GetProfileResponse> {
  disabled?: boolean
}

export interface UpdateProfileApiResponse {
  success: true
}
export interface UpdateProfileResponse {
  success: true
}

export const updateProfile = async (
  profileData: UpdateProfileRequest
): Promise<UpdateProfileResponse> => {
  const requestData = toSnakeCase(profileData) as UpdateProfileApiRequest

  const response = await httpClient.put<
    UpdateProfileApiResponse,
    HttpClientResponse<ApiCommonSuccessResponse<UpdateProfileApiResponse>>,
    UpdateProfileApiRequest
  >('/api/profile', requestData)

  return response.data.data
}
