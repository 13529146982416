import { httpClient, HttpClientResponse } from '@/api/httpClient'

import { ApiCommonSuccessResponse, UnixTimestamp } from '@/api/utils'

interface ReignInApiResponse {
  access_token: string
  refresh_token: string
  expires_at: UnixTimestamp
}

export interface ReignInResponse {
  accessToken: string
  refreshToken: string
  expiresAt: UnixTimestamp
}

export const resignIn = async (): Promise<ReignInResponse> => {
  const { data } = await httpClient.post<
    ReignInApiResponse,
    HttpClientResponse<ApiCommonSuccessResponse<ReignInApiResponse>>
  >('/api/auth/resignin')

  return {
    accessToken: data.data.access_token,
    refreshToken: data.data.refresh_token,
    expiresAt: data.data.expires_at * 1000,
  }
}
