import { inject, InjectionKey } from 'vue'

export function useSafeInject<T>(
  key: InjectionKey<T>,
  fallbackValue?: T,
  errorMessage?: string
): T {
  const injectedRef = inject(key, fallbackValue) ?? fallbackValue

  if (!injectedRef) {
    throw new Error(errorMessage || `${key.description} is not provided!`)
  }

  return injectedRef
}
