import { defineStore } from 'pinia'
import { computed, ref, toRefs } from 'vue'

export const useMobileLayoutStore = defineStore('mobileLayout', () => {
  const globalSearchRef = ref<HTMLInputElement | null>(null)
  const isMobileMainMenuOpen = ref(false)
  const isMobileProfilePanelOpen = ref(false)
  const isMobileAuthPanelOpen = ref(false)
  const isMobileDepositPanelOpen = ref(false)
  const authPanelTabIndex = ref(0)

  const isGlobalSearchFocused = ref(false)

  const isMobileBottomNavbarOpen = computed(() => {
    return !isMobileAuthPanelOpen.value
  })

  return toRefs({
    globalSearchRef,
    isMobileMainMenuOpen,
    isMobileProfilePanelOpen,
    isMobileAuthPanelOpen,
    isMobileDepositPanelOpen,
    isGlobalSearchFocused,
    isMobileBottomNavbarOpen,
    authPanelTabIndex,
  })
})
