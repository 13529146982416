<template>
  <AppForm
    :error-message="errorMessage"
    :loading="loading"
    :submit-text="$t('dialogs.forgotPassword.submitButton')"
    :keep-size="true"
    @submit="handleSubmit"
  >
    <AppInput
      v-model="fieldsData.email"
      required
      :placeholder="$t('dialogs.signUp.email-placeholder')"
      :error="!!validationResult.email.$messages.length"
      :error-message="validationResult.email.$messages[0]"
      class="mb-ne-16"
      label="E-mail"
      name="email"
      autocomplete="email"
      :light="!isMobileLayout"
    />
  </AppForm>
</template>

<script lang="ts">
import { defineComponent, PropType, computed, reactive, ref } from 'vue'
import useValidate from 'vue-tiny-validate'
import { z } from 'zod'

import AppForm from '@/components/app/AppForm.vue'
import AppInput from '@/components/app/AppInput.vue'
import { useI18n } from 'vue-i18n'
import { useSafeInject } from '@/utils/useSafeInject'
import { IS_MOBILE_LAYOUT_KEY } from '@/layouts/DefaultLayout/MobileLayout/DataContext/IS_MOBILE_LAYOUT_KEY'

export interface EmittedForgotPasswordData {
  email: string
}

export default defineComponent({
  name: 'ForgotPasswordForm',

  components: { AppForm, AppInput },

  props: {
    errorMessage: {
      type: String as PropType<string | null>,
      default: null,
    },
    withCancel: {
      type: Boolean,
      default: false,
    },
    submitText: {
      type: String as PropType<string | null>,
      default: null,
    },
    loading: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
  },

  emits: ['submit', 'forgotPassword'],

  setup(props, context) {
    const { t } = useI18n({ useScope: 'global' })
    const isMobileLayout = useSafeInject(IS_MOBILE_LAYOUT_KEY, ref(false))

    const fieldsData = reactive({
      email: '',
    })

    const fieldsRules = computed(() => {
      const requiredCheck = (value: string | number): boolean =>
        z.string().nonempty().safeParse(value).success
      const emailCheck = (value: string) =>
        z.string().email().safeParse(value).success

      const required = {
        test: requiredCheck,
        message: t('validation-errors.required'),
        name: 'required',
      }
      const email = {
        test: emailCheck,
        message: t('validation-errors.email-not-correct'),
        name: 'email',
      }

      return {
        email: [required, email],
      }
    })

    const { result: validationResult } = useValidate(fieldsData, fieldsRules)

    const handleSubmit = () => {
      validationResult.value.$test()

      if (!validationResult.value.$invalid) {
        const submitData: EmittedForgotPasswordData = { ...fieldsData }
        context.emit('submit', submitData)
      }
    }

    return { fieldsData, validationResult, handleSubmit, isMobileLayout }
  },
})
</script>
