import { formatAmountInCurrency } from '@/utils'
import { BonusType } from '@/types'
import { AvailableBonusItem } from '@/api/actions/getAvailableBonuses'

export interface BonusListItemTransformed {
  originalId: number
  id: number
  title: string

  multiplierInPercent: number
  maxDeposit: string
  minDeposit: string
  freespinCount?: number | null
  willBeIssued: 1 | 0
  currency: string
  amount: number | null
  type: BonusType
}

export function transformBonusesList(
  // rawBonuses: BonusListItem[]
  rawBonuses: AvailableBonusItem[]
): BonusListItemTransformed[] {
  return rawBonuses.map((rawBonus) => {
    let { id, title, multiplier, max, currency, ruleDepositMin } = rawBonus

    const { amount, type } = rawBonus

    const originalId = id

    let freespinCount = undefined

    const maxFormated = max?.replace(/\s/g, '')
    const maxDeposit = formatAmountInCurrency(Number(maxFormated), currency)

    const category =
      rawBonus.bonusItemCategories && rawBonus.bonusItemCategories[0]

    if (category) {
      id = category.id
      title = category.title

      const depositBonusFromCategory = category.bonuses.find(
        (item) => item.type === BonusType.DEPOSIT
      )
      const freespinBonusFromCategory = category.bonuses.find(
        (item) => item.type === BonusType.FREESPIN
      )

      if (depositBonusFromCategory) {
        multiplier = depositBonusFromCategory.multiplier
        max = depositBonusFromCategory.max
        currency = depositBonusFromCategory.currency
        ruleDepositMin = depositBonusFromCategory.ruleDepositMin
      }

      if (freespinBonusFromCategory) {
        freespinCount = freespinBonusFromCategory.countSpins
      }
    }

    if (!freespinCount && rawBonus.type === 'freespin') {
      freespinCount = rawBonus.countSpins
    }

    return {
      amount,
      type,
      currency,
      originalId,
      id,
      title,
      multiplierInPercent: Number(multiplier) * 100,
      maxDeposit,
      minDeposit: formatAmountInCurrency(
        Number(ruleDepositMin?.replaceAll(' ', '')),
        currency
      ),
      freespinCount,
      willBeIssued: rawBonus.willBeIssued,
    }
  })
}
