<template>
  <div
    class="w-[fit-content] flex justify-center items-center px-ne-8 py-ne-4 rounded-[4px] text-ne-12"
    :class="badgeColorClass"
  >
    <span>
      <slot />
    </span>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'AppBadge',

  props: {
    type: {
      type: String as PropType<'success' | 'warning' | 'error' | 'neutral'>,
      default: 'neutral',
    },
    secondary: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const badgeColorClass = computed(() => {
      if (!props.secondary) {
        switch (props.type) {
          case 'neutral':
            return 'bg-ne-neutral'
          case 'success':
            return 'bg-ne-mint'
          case 'warning':
            return 'bg-ne-yellow'
          case 'error':
            return 'bg-ne-error'
        }
      } else {
        switch (props.type) {
          case 'neutral':
            return 'bg-ne-bg-4'
          case 'success':
            return 'bg-[#12424E]'
          case 'warning':
            return 'bg-[#5F4632]'
          case 'error':
            return 'bg-[#580A3B]'
        }
      }

      return ''
    })

    return {
      badgeColorClass,
    }
  },
})
</script>
