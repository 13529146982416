import { ProfileWallet } from '@/api/actions/getProfile'

export type Brand<K, T> = K & { __brand: T }

export type ISODateString = string // 2024-12-12T16:17:13.481Z
export type DateTimeString = string // 2024-12-12 00:00:00

export enum BonusType {
  DEPOSIT = 'deposit',
  MANUAL = 'manual',
  FREESPIN = 'freespin',
  CASH = 'cash',
}

export type VipStatusName =
  | 'Opal'
  | 'Sapphire'
  | 'Emerald'
  | 'Ruby'
  | 'Diamond'
  | 'Cryptonit'
  | 'Crystal'
  | 'Zircon'
  | 'Garnet'
  | 'Onyx'
  | 'Topaz'
  | 'Pyrite'
  | 'Citrine'
  | 'Alexandrite'

export type CurrencyWalletForSelect = {
  mainCurrencyRecalculationBalance?: string
} & ProfileWallet
