<template>
  <button
    v-bind="$attrs"
    :disabled="disabled || loading"
    class="app-button"
    :class="classList"
  >
    <slot>
      {{ text }}
    </slot>
    <AppSpinner v-if="loading" theme="white" class="ml-ne-4"></AppSpinner>
  </button>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import AppSpinner from '@/components/app/AppSpinner.vue'

export default defineComponent({
  name: 'AppButton',

  components: {
    AppSpinner,
  },

  props: {
    size: {
      type: String as PropType<'small' | 'medium'>,
      default: 'medium',
    },
    view: {
      type: String as PropType<'accent' | 'primary' | 'secondary'>,
      default: 'primary',
    },
    icon: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    full: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const classList = computed(() => {
      const result = []

      if (props.size === 'small') {
        result.push('app-button--size-small')
      } else if (props.size === 'medium') {
        result.push('app-button--size-medium')
      }

      if (props.view === 'primary') {
        result.push('app-button--view-primary')
      } else if (props.view === 'accent') {
        result.push('app-button--view-accent')
      } else if (props.view === 'secondary') {
        result.push('app-button--view-secondary')
      }

      if (props.full) {
        result.push('w-full')
      }

      if (props.icon) {
        result.push('px-0')
      }

      return result
    })

    return {
      classList,
    }
  },
})
</script>
