<template>
  <div class="h-[104px] bg-ne-bg-4 rounded-ne-s flex items-center gap-4 px-6">
    <div class="min-w-[64px] max-w-[64px] h-[64px] flex">
      <img class="w-full" alt="avatar" :src="profileStatusAvatarUrl" />
    </div>
    <div class="flex flex-col w-full items-start">
      <div class="text-[20px] font-semibold">
        {{ profileData?.nickname }}
      </div>
      <div class="pt-[8px] flex justify-center items-center font-normal">
        <span class="text-ne-14">
          {{ profileData?.vip.statuses.current.title }}
        </span>

        <div class="w-[1px] h-[10px] bg-white mx-3"></div>
        <span class="text-ne-14"> {{ profileData?.vip.cp }} CP </span>
      </div>
      <div class="w-full pt-[16px]">
        <div class="w-full bg-ne-bg-5 h-1 rounded-full overflow-hidden">
          <div
            class="bg-ne-violet-light h-full transition-width duration-300"
            :style="{ width: `${isHighestProfileStatus ? 100 : progress}%` }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { toRefs } from 'vue'
import { useProfileStore } from '@/stores/profileStore'

const {
  profileStatusAvatarUrl,
  profileData,
  profileStatusProgress: progress,
  isHighestProfileStatus,
} = toRefs(useProfileStore())
</script>
