import { Ref, watch } from 'vue'

export const useBodyOverflow = (open: Ref<boolean>) => {
  watch(
    () => open.value,
    (value) => {
      value
        ? document.body.classList.add('overflow-hidden')
        : document.body.classList.remove('overflow-hidden')
    }
  )
}
